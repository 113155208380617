<template>
  <div class="row">
    <div class="col-md-12">
      <div class="eventsNews m-3 mb-0 ">
        <div class="">
          <h3>EVENTS & NEWS</h3>

          <div class="tabs">
            <ul class="nav nav-tabs nav-pills" role="tablist">
              <li class="nav-item " role="presentation">
                <a class="nav-link active" id="disabled-tab-0" data-bs-toggle="tab"
                   href="#disabled-tabpanel-0" role="tab" aria-controls="disabled-tabpanel-0"
                   aria-selected="true">Events</a>
              </li>
              <li class="nav-item nav-pills" role="presentation">
                <a class="nav-link" id="disabled-tab-1" data-bs-toggle="tab"
                   href="#disabled-tabpanel-1" role="tab" aria-controls="disabled-tabpanel-1"
                   aria-selected="false">News</a>
              </li>

            </ul>
            <div class="tab-content pt-3" id="tab-content">
              <div class="tab-pane active" id="disabled-tabpanel-0" role="tabpanel" aria-labelledby="disabled-tab-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6" v-for="event in events" :key="event.eventId">
                        <div class="media">
                          <router-link class="pull-left" to="#">
                      <span class="dateEl"><em>{{ event.date.split(",")[1].trim().split(' ')[1] }}</em>
                      {{ event.date.split(",")[1].trim().slice(0, 3) }}</span>
                          </router-link>
                          <div class="media-body">
                            <h4 class="media-heading">
                              <router-link to="#">{{ event.title }}</router-link>
                            </h4>
                            <div class="meta-data">
                              <span class="longDate">{{ event.date }}</span><br> <span
                                class="timeEl"><i class="fa fa-clock-o"
                                                  aria-hidden="true"></i> 152:00pm - 02:00pm</span>
                            </div>
                            <p>
                              {{ event.description.slice(0, 100) + "...." }}
                            </p>
                          </div><!-- / media-body -->
                        </div>
                      </div>
                    </div>


                  </div>

                </div><!-- / row -->
                <div class="text-center">
                  <br>
                  <div class="links">
                    <router-link to="AllEvents">SEE ALL EVENTS</router-link>
                  </div>

                </div>
              </div>
              <div class="tab-pane" id="disabled-tabpanel-1" role="tabpanel" aria-labelledby="disabled-tab-1">

                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6" v-for="event in events" :key="event.eventId">
                        <div class="media">
                          <router-link class="pull-left" to="#"><span
                              class="dateEl"><em>{{ event.date.split(",")[1].trim().split(' ')[1] }}</em>
                      {{ event.date.split(",")[1].trim().slice(0, 9) }}</span></router-link>
                          <div class="media-body">
                            <h4 class="media-heading">
                              <router-link to="#">{{ event.title }}</router-link>
                            </h4>
                            <div class="meta-data">
                              <span class="longDate">{{ event.date }}</span><br>

                            </div>
                            <p>
                              {{ event.description.slice(0, 200) + "...." }}
                            </p>
                          </div><!-- / media-body -->
                        </div>
                      </div>
                    </div>


                  </div>

                </div>
                <div class="text-center">
                  <br>
                  <div class="links">
                    <router-link to="AllEvents">SEE ALL EVENTS</router-link>
                  </div>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>
<script>
import {services} from "@/services/axios";

export default {
  name: "EventsCalender",
  data() {
    return {
      errorMessage: "",


      events: [
        {
          "eventId": 1,
          "description": "A fascinating annual tech conference showcasing the latest innovations.",
          "category": "Technology",
          "dateCreated": "June 15, 2023",
          "date": "September 10, 2023",
          "image": "https://example.com/tech-conference.jpg",
          "status": "Upcoming",
          "subCategory": "Software",
          "title": "Tech Expo 2023"
        },
        {
          "eventId": 2,
          "description": "A vibrant music festival featuring both local and international artists.",
          "category": "Entertainment",
          "dateCreated": "April 20, 2023",
          "date": "July 25, 2023",
          "image": "https://example.com/music-festival.jpg",
          "status": "Ongoing",
          "subCategory": "Music",
          "title": "Summer Beats Festival"
        },
        {
          "eventId": 3,
          "description": "An educational symposium discussing the latest advancements in renewable energy solutions.",
          "category": "Environment",
          "dateCreated": "February 1, 2023",
          "date": "November 12, 2023",
          "image": "https://example.com/energy-symposium.jpg",
          "status": "Upcoming",
          "subCategory": "Energy",
          "title": "Renewable Energy Symposium"
        },
        {
          "eventId": 4,
          "description": "A culinary extravaganza showcasing the diverse flavors of the region.",
          "category": "Food",
          "dateCreated": "July 1, 2023",
          "date": "August 18, 2023",
          "image": "https://example.com/food-festival.jpg",
          "status": "Upcoming",
          "subCategory": "Cuisine",
          "title": "Flavor Fest 2023"
        },
        {
          "eventId": 5,
          "description": "An art exhibition featuring the works of renowned local and international artists.",
          "category": "Arts",
          "dateCreated": "March 15, 2023",
          "date": "June 30, 2023",
          "image": "https://example.com/art-exhibition.jpg",
          "status": "Completed",
          "subCategory": "Visual Arts",
          "title": "Artistic Expressions"
        }
      ]
    }


  },
  async created() {
    // try {
    //   let response=await services.getAllEvents()
    //   this.events=response.data
    //   console.log('in home page',this.events);
    // }
    // catch (e) {
    //   this.errorMessage=e
    //
    // }
    this.convertDate()
  },
  methods: {
    convertDate() {
      for (let i = 0; i <= this.events.length; i++) {

        const object = this.events[i]

        try {
          const date = new Date(object['date'])


          const longDate = date.toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric"

          })
          this.events[i].date = longDate


        } catch (e) {
        }


      }

    }
  }
}
</script>
<style scoped>
.eventsNews {
  padding: 20px;
}

h3 {
  color:#2c3e50;
  font-size: 28px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background: #2c3e50;
  border-bottom: 3px solid #FFC000;
}

.nav-pills > li > a {
  border-radius: 0;
  width: 280px;
  padding: 6px 16px;
  margin: 1px;
  background: #636363;
  color: white;
  border-bottom: 3px solid #2c3e50;
  font-size: 20px;
}

.tab-content {
  margin: 0 0 10px 0;
}

.media > .pull-left {
  margin-right: 12px;
  margin-bottom: 12px;
}

.media .dateEl {
  display: inline-block;
  text-align: center;
  background: #2c3e50;
  padding: 18px 0 18px 0;
  color: #ffff;
  font-size: 16px;
  font-weight: 700;
  width: 113px;
  text-transform: uppercase;
}

.media .dateEl em {
  display: block;
  color: #ffffff;
  font-size: 42px;

  margin-bottom: 5px;
}

.media .media-heading a {
  font-size: 16px;
  color: #2c3e50;

}

.media .meta-data {
  margin: 0 0 7px 0;
}

.media .longDate, .media .timeEl {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #636465;

  min-height: 16px;
}

.media .longDate {

  padding: 0 10px 0 0;

  margin-right: 3px;
}

#tab1.tab-pane .media .longDate {
  border-right: none;
}

.blogPost--small .media > .pull-left {
  margin-right: 25px;
}


.blogPost--small .date span, .blogPost--small .dateEl2 span {
  display: inline-block;
  font-size: 42px;
  line-height: 1;
  letter-spacing: 0em;
  text-indent: -0.1em;
  color: #fff;
  font-weight: bold;
}

.blogPost--small .date small, .blogPost--small .dateEl2 small {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

.blogPost--small h4 {
  margin: 0 0 12px 0;
  font-size: 22px;
  text-transform: uppercase;
}
</style>
